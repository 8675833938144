import * as fileSaver from 'file-saver';
import { useFirebase } from '~/composables/use-firebase';
import { customAlphabet } from 'nanoid/non-secure';
import dictionary from 'nanoid-dictionary';
import { notify } from '@kyvg/vue3-notification';
import { NotificationType } from '~/components/notification';
import { useLoader } from '~/composables/use-loader';
import { hasFileUrlExtensions } from '~/pages/_components/content-details/content.utils';

const nanoid = customAlphabet(dictionary.alphanumeric, 20);

export const getUrlFileExtension = (fileUrl: string) => {
  const { pathname } = new URL(fileUrl);
  return pathname.split('.').pop();
};

export const downloadFile = async (fileUrl: string, fileName?: string) => {
  await useLoader({
    action: async () => {
      const res = await fetch(fileUrl);
      const blob = await res.blob();

      fileSaver.saveAs(
        blob,
        fileName ?? `file.${getUrlFileExtension(fileUrl)}`,
      );
    },
    onError: () => {
      notify({
        group: NotificationType.ERROR,
        title:
          'Something went wrong while trying to process a file download request. Pleas try again later.',
      });
    },
  });
};

export const createStorageFilePath = (fileName: string) => {
  const { auth } = useFirebase();

  const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

  return `${auth.currentUser?.uid}/${nanoid()}.${extension}`;
};

export const isFileImage = (fileUrl: string) => {
  const extensions = ['.png', '.jpg', '.jpeg', '.gif'];
  return hasFileUrlExtensions(extensions, fileUrl);
};

export const isFileVideo = (fileUrl: string) => {
  const extensions = ['.mp4', '.mov', '.ogg', '.webm'];
  return hasFileUrlExtensions(extensions, fileUrl);
};
